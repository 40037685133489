/*
 * Secondary camera InterviewEnded Messages
 *
 * This contains all the text for the Secondary camera LandingPage
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.SecondaryCamera.InterviewEndedPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'The interview has ended.',
  },
  content: {
    id: `${scope}.content`,
    defaultMessage: 'You can close this tab now.',
  },
});
