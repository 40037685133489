import { get } from 'lodash';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { growthBookFeatureFlags } from 'utils/featureFlags';
import { eventLogAction } from 'utils/mixpanelActions';
import { getWindowObject } from 'containers/InterviewRooms/utils';
import VideoServiceInterface from 'containers/IRRedirect/videoServiceInterface';
import { ROLE_MAP } from 'utils/constants';

export const useEventLogger = () => {
  const videoService = VideoServiceInterface();
  const isMixpanelEnabled = useFeatureValue(growthBookFeatureFlags.MIX_PANEL_ENABLED);
  const meeting_details = getWindowObject('meeting_details') || {};
  const meetingId = get(meeting_details, 'meeting_id', null);
  const participantId = get(meeting_details, 'participant_id', null);
  const tenantIdParam = get(meeting_details, 'tenant_id', null);
  const tenant_name = get(meeting_details, 'tenant_name', null);
  const participantRole = get(meeting_details, 'participant_role', 1);

  const logEvent = (event, prop, ids) => {
    if (isMixpanelEnabled) {
      eventLogAction({
        event,
        payload: {
          ...prop,
          meetingId: ids?.meetingId || meetingId,
          participantId: ids?.participantId || participantId,
          tenantIdParam: ids?.tenant_id_param || tenantIdParam,
          tenantName: ids?.tenant_name || tenant_name,
          participantRole: ids?.role || ROLE_MAP[participantRole],
          videoServiceProvider: videoService?.irVideoServiceProvider,
        },
      });
    }
  };

  return logEvent;
};
