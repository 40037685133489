import produce from 'immer';
import { UPDATE_RECORDING_STATUS_COMPLETE } from 'containers/InterviewRooms/constants';
import {
  INIT_COMPLETE,
  FETCH_USER_DETAILS_COMPLETE,
  FETCH_MEETING_DETAILS_COMPLETE,
  FETCH_RECRUIT_TOKEN,
  UPDATE_CUSTOM_TOKEN,
  UPDATE_RECRUIT_TOKEN,
  FETCH_ROOM_DETAILS_COMPLETE,
  FETCH_RESUME_URL_COMPLETE,
  SET_IR_PARENT,
  SET_CHAT_MESSAGE_DATA,
  SET_MESSAGE_REF_DATA,
  SET_PARTICIPANT_DATA,
  SET_PARTICIPANT_REF_DATA,
  SET_SIGNAL_DATA,
  SET_SELF_NOTIFICATION_DATA,
  SET_SIGNAL_REF_DATA,
  SET_AUTH_COMPLETE,
  JOIN_INTERVIEW_ROOMS,
  GET_RECRUIT_CANDIDATE_DETAILS,
  GET_CANDIDATE_DETAILS_COMPLETE,
  SET_LOADING_TIME,
  SET_PRIVATE_MESSAGE_REF_DATA,
  SET_PRIVATE_CHAT_MESSAGE_DATA,
  UPDATE_SELF_NOTIFICATIONS_LIST,
  SET_VOIP_STATUS_DATA,
  SET_VOIP_STATUS_REF_DATA,
  SET_SEC_CAM_ENV_CHECK_DATA,
  SET_SEC_CAM_ENV_CHECK_REF_DATA,
} from './constants';

export const initialState = {
  jwt_token: null,
  recruitTokenData: null,
  customTokenData: null,
  isLoading: false,
  rToken: null,
  cToken: null,
  tenant_id: null,
  tenantName: null,
  roomDetails: {
    token: null,
    roomId: null,
    date_created: null,
    date_updated: null,
    duration: null,
    empty_room_timeout: null,
    end_time: null,
    record_participants_on_connect: false,
    is_candidate_recording_consent: true,
    status: null,
    type: null,
    pId: null,
    videoServiceProvider: null,
  },
  candidateDetails: {
    candidateId: null,
    firstName: '',
    lastName: '',
    resumeFileId: null,
    email: '',
    externalId: null,
    contact: null,
    isPhoneNumberAvailable: false,
    jobDescription: null,
  },
  resumeState: { isLoading: false, resumeUrl: null, error: null },
  authUserData: {},
  recordingState: { success: null, error: null, recordingStatus: false },
  isCandidate: false,
  meetingDetails: [],
  meetingName: 'Evaluation',
  sectionName: 'Interview',
  parentValue: 'TOP',
  candidateOldId: null,
  messageRefData: null,
  privateMessageRefData: null,
  signalRefData: null,
  participantRefData: null,
  chatMessageDataList: [],
  privateChatMessageDataList: [],
  signalDataList: [],
  selfNotificationDataList: [],
  participantData: null,
  uniqueParticipantsData: null,
  isAuthComplete: false,
  loadingTime: 0,
  voipStatusData: null,
  voipStatusRefData: null,
  secCamEnvCheckData: null,
  secCamEnvCheckRefData: null,
};

/* eslint-disable default-case, no-param-reassign */
const interviewRoomsRedirectReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case JOIN_INTERVIEW_ROOMS:
        draft.roomDetails.token = null;
        break;
      case INIT_COMPLETE:
        draft.roomDetails.token = action.roomDetails.token;
        draft.roomDetails.roomId = action.roomDetails.roomId;
        draft.roomDetails.pId = action.roomDetails.pId;
        draft.roomDetails.is_candidate_recording_consent = action.roomDetails.is_candidate_recording_consent;
        draft.recordingState.recordingStatus = action.urlRecordingParam;
        draft.roomDetails.videoServiceProvider = action.roomDetails.videoServiceProvider;
        break;
      case FETCH_USER_DETAILS_COMPLETE:
        draft.authUserData = action.authUserData;
        draft.tenant_id = action.tenantId;
        draft.tenantName = action.tenantName;
        draft.isCandidate = action.isCandidate;
        draft.user_role = action.user_role;
        break;
      case FETCH_MEETING_DETAILS_COMPLETE:
        draft.meetingDetails = action.meetingDetails;
        draft.meetingName = action.meetingName;
        draft.sectionName = action.sectionName;
        break;
      case FETCH_ROOM_DETAILS_COMPLETE:
        draft.roomDetails.date_created = action.data.date_created;
        draft.roomDetails.date_updated = action.data.date_updated;
        draft.roomDetails.duration = action.data.duration;
        draft.roomDetails.empty_room_timeout = action.data.empty_room_timeout;
        draft.roomDetails.end_time = action.data.end_time;
        draft.roomDetails.record_participants_on_connect = action.data.record_participants_on_connect;
        draft.roomDetails.status = action.data.status;
        draft.roomDetails.type = action.data.type;
        break;
      case UPDATE_RECORDING_STATUS_COMPLETE:
        draft.recordingState.success = action.recordingState.success;
        draft.recordingState.error = action.recordingState.error;
        draft.recordingState.recordingStatus = action.recordingState.recordingStatus;
        break;
      case FETCH_RECRUIT_TOKEN:
        draft.id_token = action.id_token;
        draft.jwt_token = action.jwt_token;
        draft.custom_token = action.custom_token;
        break;
      case UPDATE_RECRUIT_TOKEN:
        draft.recruitTokenData = action.recruitTokenData;
        break;
      case UPDATE_CUSTOM_TOKEN:
        draft.customTokenData = action.customTokenData;
        draft.user_default_role = action.user_default_role;
        break;
      case SET_IR_PARENT:
        draft.parentValue = action.parentValue;
        draft.candidateOldId = action.candidateOldId;
        break;
      case SET_MESSAGE_REF_DATA:
        draft.messageRefData = action.messageRefData;
        break;
      case SET_PRIVATE_MESSAGE_REF_DATA:
        draft.privateMessageRefData = action.privateMessageRefData;
        break;
      case SET_SIGNAL_REF_DATA:
        draft.signalRefData = action.signalRefData;
        break;
      case SET_PARTICIPANT_REF_DATA:
        draft.participantRefData = action.participantRefData;
        break;
      case SET_CHAT_MESSAGE_DATA:
        draft.chatMessageDataList = action.chatMessageDataList;
        break;
      case SET_PRIVATE_CHAT_MESSAGE_DATA:
        draft.privateChatMessageDataList = action.privateChatMessageDataList;
        break;
      case SET_SIGNAL_DATA:
        draft.signalDataList = action.signalDataList;
        break;
      case SET_SELF_NOTIFICATION_DATA:
        draft.selfNotificationDataList = [...draft.selfNotificationDataList, action.selfNotificationData] || [];
        break;
      case UPDATE_SELF_NOTIFICATIONS_LIST:
        draft.selfNotificationDataList = action.updatedSelfNotificationsList || draft.selfNotificationDataList;
        break;
      case SET_PARTICIPANT_DATA:
        draft.participantData = action.participantData;
        draft.uniqueParticipantsData = action.uniqueParticipantsData;
        break;
      case SET_AUTH_COMPLETE:
        draft.isAuthComplete = action.isAuthComplete;
        break;
      case GET_RECRUIT_CANDIDATE_DETAILS:
        draft.candidateDetails.resumeFileId = null;
        draft.resumeState.isLoading = true;
        draft.resumeState.resumeUrl = null;
        draft.resumeState.error = null;
        break;
      case GET_CANDIDATE_DETAILS_COMPLETE:
        draft.candidateDetails = action.candidateDetails;
        break;
      case FETCH_RESUME_URL_COMPLETE:
        draft.resumeState.isLoading = action.resumeState.isLoading;
        draft.resumeState.resumeUrl = action.resumeState.resumeUrl;
        draft.resumeState.error = action.resumeState.error;
        break;
      case SET_LOADING_TIME:
        draft.loadingTime = action.loadingTime;
        break;
      case SET_VOIP_STATUS_DATA:
        draft.voipStatusData = action.voipStatusData;
        break;
      case SET_VOIP_STATUS_REF_DATA:
        draft.voipStatusRefData = action.voipStatusRefData;
        break;
      case SET_SEC_CAM_ENV_CHECK_DATA:
        draft.secCamEnvCheckData = action.secCamEnvCheckData;
        break;
      case SET_SEC_CAM_ENV_CHECK_REF_DATA:
        draft.secCamEnvCheckRefData = action.secCamEnvCheckRefData;
        break;
    }
  });

export default interviewRoomsRedirectReducer;
