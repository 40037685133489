export const GLOBAL_ERROR = 'app/App/GLOBAL_ERROR';
export const SAVE_STATE_ACTION = 'app/App/SAVE_STATE_ACTION';
export const LOAD_STATE_ACTION = 'app/App/LOAD_STATE_ACTION';

export const DEFAULT_VIDEO_CONSTRAINTS = {
  resolution: {
    width: { ideal: 4608 },
    height: { ideal: 3456 },
  },
  frameRate: 24,
};

export const DEFAULT_MOBILE_VIDEO_CONSTRAINTS = {
  h360: {
    portrait: {
      width: { ideal: 360 },
      height: { ideal: 640 },
    },
    landscape: {
      width: { ideal: 640 },
      height: { ideal: 360 },
    },
  },
  h540: {
    portrait: {
      width: { ideal: 540 },
      height: { ideal: 960 },
    },
    landscape: {
      width: { ideal: 960 },
      height: { ideal: 540 },
    },
  },
};

export const USER_MEDIA_CHECK = { camera: { video: true }, microphone: { audio: true } };

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'app/App/selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'app/App/selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'app/App/selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'app/App/selectedBackgroundSettings';

export const userColor = [
  { color: '#ecd444', light: '#ecd44433' },
  { color: '#ee6352', light: '#ee635233' },
  { color: '#9ac2c9', light: '#9ac2c933' },
  { color: '#8acb88', light: '#8acb8833' },
  { color: '#1be7ff', light: '#1be7ff33' },
];

export const CAMERA_FACING_MODE_CONSTRAINTS = {
  USER: 'user',
  ENVIRONMENT: 'environment',
  LEFT: 'left',
  RIGHT: 'right',
};
