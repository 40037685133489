/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  JOIN_ROOM_COMPLETE,
  SET_BROWSER_PERMISSION_DENIED,
  SET_IS_CONNECTING,
  SET_VIDEO_TRACK,
  UPDATE_CUSTOM_TOKEN,
  GLOBAL_ERROR,
  FETCH_USER_DETAILS_COMPLETE,
  SET_PARTICIPANT_DATA,
  SET_PARTICIPANT_REF_DATA,
  SET_SEC_CAM_ENV_CHECK_REF_DATA,
  SET_DEFAULT_PROVIDER,
  SET_JOIN_BTN_CLICKED,
  SET_SHOW_POPUP,
  SET_IS_RECONNECTING,
  SET_SEC_CAM_CONNECTED,
} from 'containers/SecondaryCamera/constants';

export const initialState = {
  customTokenData: null,
  authUserData: null,
  tenantId: null,
  tenantName: null,
  userDefaultRole: null,
  roomDetails: {
    roomToken: null,
    roomId: null,
    videoServiceProvider: null,
    participantId: null,
    candidateRecordingConsent: null,
  },
  urlRecordingParam: false,
  videoTrack: null,
  isConnecting: false,
  isBrowserPermissionDenied: false,
  isReconnecting: false,
  error: null,
  participantData: null,
  participantRefData: null,
  defaultProvider: null,
  joinBtnClicked: false,
  showPopup: true,
  isSecCamConnected: false,
  secCamEnvCheckRefData: null,
};

const secondaryCameraReducer = (state = initialState, action) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case UPDATE_CUSTOM_TOKEN:
        draft.customTokenData = action.customTokenData;
        draft.userDefaultRole = action.userDefaultRole;
        break;
      case JOIN_ROOM_COMPLETE:
        draft.roomDetails = action.roomDetails;
        draft.urlRecordingParam = action.urlRecordingParam;
        break;
      case SET_VIDEO_TRACK:
        draft.videoTrack = action.track;
        break;
      case SET_IS_CONNECTING:
        draft.isConnecting = action.status;
        break;
      case SET_BROWSER_PERMISSION_DENIED:
        draft.isBrowserPermissionDenied = action.status;
        break;
      case GLOBAL_ERROR:
        draft.error = action.error;
        break;
      case FETCH_USER_DETAILS_COMPLETE:
        draft.authUserData = action.authUserData;
        draft.tenantId = action.tenantId;
        draft.tenantName = action.tenantName;
        break;
      case SET_PARTICIPANT_DATA:
        draft.participantData = action.participantData;
        break;
      case SET_PARTICIPANT_REF_DATA:
        draft.participantRefData = action.participantRefData;
        break;
      case SET_SEC_CAM_ENV_CHECK_REF_DATA:
        draft.secCamEnvCheckRefData = action.secCamEnvCheckRefData;
        break;
      case SET_DEFAULT_PROVIDER:
        draft.defaultProvider = action.defaultProvider;
        break;
      case SET_JOIN_BTN_CLICKED:
        draft.joinBtnClicked = action.status;
        break;
      case SET_SHOW_POPUP:
        draft.showPopup = action.status;
        break;
      case SET_IS_RECONNECTING:
        draft.isReconnecting = action.status;
        break;
      case SET_SEC_CAM_CONNECTED:
        draft.isSecCamConnected = action.isSecCamConnected;
        break;
    }
  });

export default secondaryCameraReducer;
