import { useCallback, useState } from 'react';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { growthBookFeatureFlags } from 'utils/featureFlags';
import VideoServiceInterface from 'containers/IRRedirect/videoServiceInterface';
import { applyEffectOnInitialization } from 'containers/InterviewRooms/utils';
import { SIGNAL_MESSAGE } from 'utils/signalConstants';

export default function useLocalVideoToggle(props) {
  const { room, localTracks, getLocalVideoTrack, removeLocalVideoTrack, onError = () => {} } = props || {};
  const { background_images } = useFeatureValue(growthBookFeatureFlags.INTERVIEW_ROOMS_VIDEO_BACKGROUND_EFFECT) || {};
  const { irVideoServiceProvider } = VideoServiceInterface();
  const localParticipant = room?.localParticipant;
  const isScreenTrack = track => track?.name?.includes('screen') || track?.source === 'screen_share';
  const videoTrack = localTracks?.find(track => track?.kind === 'video' && !isScreenTrack(track)) ?? null;

  const [isPublishing, setIsPublishing] = useState(false);

  const onEnableVideoError = err => {
    if (err && err.toString().includes('Permission'))
      onError({ signalMessage: SIGNAL_MESSAGE.ENABLE_CAMERA_PERMISSIONS });
    else if (!err && err.toString().includes('Device in use'))
      onError({ signalMessage: SIGNAL_MESSAGE.VIDEO_DEVICE_IN_USE });
    else onError({ signalMessage: SIGNAL_MESSAGE.VIDEO_DEVICE_HARDWARE_ISSUE });
    if (onError) onError(err);
  };

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setIsPublishing(true);
        getLocalVideoTrack()
          ?.then(async track => {
            await applyEffectOnInitialization(track, irVideoServiceProvider, background_images);
            await localParticipant?.publishTrack(track, { priority: 'high' });
          })
          .catch(onEnableVideoError)
          .finally(() => setIsPublishing(false));
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack]);

  return [!!videoTrack, toggleVideoEnabled];
}
