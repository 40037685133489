import { useCallback, useState } from 'react';
import { includes } from 'lodash';
import { createLocalTracks } from 'livekit-client';
import Video from 'twilio-video';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { getDeviceInfo, isPermissionDenied } from 'utils/media';
import { err } from 'utils/logger';
import { growthBookFeatureFlags } from 'utils/featureFlags';
import { useLocalStorage } from 'hooks/useLocalStorage';
import VideoServiceInterface from 'containers/IRRedirect/videoServiceInterface';
import {
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
  CAMERA_FACING_MODE_CONSTRAINTS,
} from 'containers/App/constants';
import { TRACKS_ACQUIRING_ERROR } from 'components/PreJoinLobby/constant';
import {
  applyEffectOnInitialization,
  isDefinedAndNotNull,
  updateMediaConstraints,
} from 'containers/InterviewRooms/utils';
import { providerOptions } from 'contexts/VideoProvider/constants';
import { SIGNAL_MESSAGE } from 'utils/signalConstants';

export default function useLocalTracks(props) {
  const { onError = () => {} } = props || {};
  const videoService = VideoServiceInterface();
  const { background_images } = useFeatureValue(growthBookFeatureFlags.INTERVIEW_ROOMS_VIDEO_BACKGROUND_EFFECT) || {};
  const [audioTrack, setAudioTrack] = useState(null);
  const [videoTrack, setVideoTrack] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);

  const [isLocalTracksAcquired, setIsLocalTracksAcquired] = useState(false);
  const [browserPermissions, setBrowserPermissions] = useState({
    cameraPermissionDenied: true,
    microphonePermissionDenied: true,
  });
  const [tracksAcquiringError, setTracksAcquiringError] = useState(null);
  const [localConnectedTracks, setLocalConnectedTracks] = useState([]);
  const [localTrackConstraints, setLocalTracksConstraints] = useState(null);
  const [storedAudioDeviceId, setStoredAudioDeviceId] = useLocalStorage(SELECTED_AUDIO_INPUT_KEY, null);
  const [storedVideoDeviceId, setStoredVideoDeviceId] = useLocalStorage(SELECTED_VIDEO_INPUT_KEY, null);

  const getLocalVideoTrack = useCallback(
    async orientation => {
      // source could be secondary camera or interview rooms
      const selectedVideoDeviceId = storedVideoDeviceId;

      const { videoInputDevices } = await getDeviceInfo();

      const hasSelectedVideoDevice = videoInputDevices.some(
        device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
      );

      const UPDATED_VIDEO_CONSTRAINTS = updateMediaConstraints(videoInputDevices, selectedVideoDeviceId, orientation);

      const options = {
        name: `camera-${Date.now()}`,
        facingMode: CAMERA_FACING_MODE_CONSTRAINTS.USER,
        ...UPDATED_VIDEO_CONSTRAINTS,
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
      };

      return videoService.createLocalVideoTrackFn(options).then(newTrack => {
        setVideoTrack(newTrack);
        if (isDefinedAndNotNull(newTrack)) {
          setLocalConnectedTracks(prevTracks => [...prevTracks, newTrack]);
        }

        return newTrack;
      });
    },
    [videoService, storedVideoDeviceId],
  );

  const removeLocalAudioTrack = () => {
    if (audioTrack) {
      audioTrack?.stop();
      setAudioTrack(undefined);
    }
  };

  const removeLocalVideoTrack = () => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  };

  const getAudioAndVideoTracks = useCallback(
    async provider => {
      removeLocalAudioTrack();
      removeLocalVideoTrack();
      let localTrackConstraintsValue = localTrackConstraints;

      if (
        !localTrackConstraints ||
        browserPermissions.cameraPermissionDenied ||
        browserPermissions.microphonePermissionDenied
      ) {
        localTrackConstraintsValue = await getLocalTracksConstraints();
      }

      const videoServiceProvider = provider || videoService.irVideoServiceProvider;

      const createTracks =
        videoServiceProvider !== providerOptions.TWILIO ? createLocalTracks : Video.createLocalTracks;

      return createTracks(localTrackConstraintsValue)
        .then(async tracks => {
          const newVideoTrack = tracks.find(track => track.kind === 'video');
          const newAudioTrack = tracks.find(track => track.kind === 'audio');
          if (newVideoTrack) {
            setVideoTrack(newVideoTrack);
            if (isDefinedAndNotNull(newVideoTrack)) {
              setLocalConnectedTracks(prevTracks => [...prevTracks, newVideoTrack]);
            }
            // Save the deviceId, so it can be picked up by the VideoInputList component. This only matters
            // in cases where the user's video is disabled.
            setStoredVideoDeviceId(newVideoTrack?.mediaStreamTrack?.getSettings()?.deviceId ?? '');
            await applyEffectOnInitialization(newVideoTrack, videoServiceProvider, background_images);
          }

          if (newAudioTrack) {
            setAudioTrack(newAudioTrack);
            if (isDefinedAndNotNull(newAudioTrack)) {
              setLocalConnectedTracks(prevTracks => [...prevTracks, newAudioTrack]);
            }
            setStoredAudioDeviceId(newAudioTrack?.mediaStreamTrack?.getSettings()?.deviceId ?? '');
            if (Object.hasOwn(newAudioTrack, 'unmute')) newAudioTrack.unmute();
          }

          return [newAudioTrack, newVideoTrack]?.filter(track => track !== undefined && track !== null);
        })
        .catch(error => {
          err('Error acquiring local media:', error);
          if (includes(error?.message, 'At least one of audio and video must be requested')) {
            onError({ signalMessage: SIGNAL_MESSAGE.BOTH_MIC_CAM_DENIED });
            setTracksAcquiringError(TRACKS_ACQUIRING_ERROR.PERMISSION_DENIED);
          } else if (error?.name === 'NotReadableError' || error?.name === 'TrackStartError') {
            setTracksAcquiringError(TRACKS_ACQUIRING_ERROR.DEVICE_IN_USE);
          } else {
            setTracksAcquiringError(TRACKS_ACQUIRING_ERROR.HARDWARE_ISSUE);
          }
          return [];
        })
        .finally(() => setIsLocalTracksAcquired(true));
    },
    [videoService],
  );

  // eslint-disable-next-line consistent-return
  const getAudioVideoStream = async trackConstraints => {
    try {
      let localTrackConstraintsValue = trackConstraints;
      if (!localTrackConstraintsValue) {
        localTrackConstraintsValue = await getLocalTracksConstraints();
      }
      const mediaStreamTracks = await getMediaStreamTracks(localTrackConstraintsValue);
      if (mediaStreamTracks) {
        const { stream, videoTracks, audioTracks } = mediaStreamTracks;
        // to be removed later
        // const videoStreamObj = new MediaStream(videoTracks);
        // const audioStreamObj = new MediaStream(audioTracks);
        setMediaStream(stream);
        // setVideoStream(videoStreamObj);
        // setAudioStream(audioStreamObj);
        return { stream, videoTracks, audioTracks };
      }
    } catch (error) {
      console.error('Error starting media stream', error);
      setTracksAcquiringError(TRACKS_ACQUIRING_ERROR.HARDWARE_ISSUE);
    }
  };

  // eslint-disable-next-line consistent-return
  const getMediaStreamTracks = async newMediaConstraints => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(newMediaConstraints);
      if (stream) {
        const videoTracks = stream.getVideoTracks();
        const audioTracks = stream.getAudioTracks();
        return { stream, videoTracks, audioTracks };
      }
    } catch (error) {
      console.log('Error getting media stream tracks', error);
      setTracksAcquiringError(TRACKS_ACQUIRING_ERROR.HARDWARE_ISSUE);
    }
  };

  const getLocalTracksConstraints = async () => {
    const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } = await getDeviceInfo();

    if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve();

    const selectedAudioDeviceId = storedAudioDeviceId;
    const selectedVideoDeviceId = storedVideoDeviceId;

    const hasSelectedAudioDevice = audioInputDevices.some(
      device => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId,
    );
    const hasSelectedVideoDevice = videoInputDevices.some(
      device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
    );
    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isCameraPermissionDenied = await isPermissionDenied('camera');
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone');

    setBrowserPermissions({
      cameraPermissionDenied: isCameraPermissionDenied,
      microphonePermissionDenied: isMicrophonePermissionDenied,
    });

    const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied; // && process.env.NODE_ENV !== 'development';
    const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied; // && process.env.NODE_ENV !== 'development';

    const UPDATED_VIDEO_CONSTRAINTS = updateMediaConstraints(videoInputDevices, selectedVideoDeviceId);

    const localTrackConstraintsObj = {
      video: shouldAcquireVideo && {
        ...UPDATED_VIDEO_CONSTRAINTS,
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
        facingMode: 'user', // In mobile to show front camera on the initial load by default
      },
      audio:
        shouldAcquireAudio &&
        (hasSelectedAudioDevice ? { deviceId: { exact: selectedAudioDeviceId } } : hasAudioInputDevices),
    };

    setLocalTracksConstraints(localTrackConstraintsObj);
    // These custom errors will be picked up by the MediaErrorSnackbar component.

    // Check for device availability and permissions before attempting to acquire tracks
    // Handle permission denials gracefully by dispatching notifications and throwing custom errors
    if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
      onError({ signalMessage: SIGNAL_MESSAGE.BOTH_MIC_CAM_DENIED });
    } else if (isCameraPermissionDenied) {
      onError({ signalMessage: SIGNAL_MESSAGE.CAM_DENIED });
    } else if (isMicrophonePermissionDenied) {
      onError({ signalMessage: SIGNAL_MESSAGE.MIC_DENIED });
    }
    if (isCameraPermissionDenied || isMicrophonePermissionDenied)
      setTracksAcquiringError(TRACKS_ACQUIRING_ERROR.PERMISSION_DENIED);
    return localTrackConstraintsObj;
  };

  const localTracks = [audioTrack, videoTrack]?.filter(track => track !== undefined && track !== null);

  return {
    localTracks,
    getLocalVideoTrack,
    isLocalTracksAcquired,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
    getLocalTracksConstraints,
    getAudioVideoStream,
    mediaStream,
    browserPermissions,
    localConnectedTracks,
    tracksAcquiringError,
  };
}
