import React from 'react';
import { FormattedMessage } from 'react-intl';
import { InterviewEndedImage } from 'images';
import message from 'components/SecondaryCamera/InterviewEndedPage/message';

export function InterviewEnded() {
  return (
    <div className="flex h-screen w-full flex-col justify-center items-center">
      <img src={InterviewEndedImage} alt="interviewEnded" />
      <p className="text-neutral-900 text-lg font-semibold mb-2 mt-4">
        <FormattedMessage {...message.title} />
      </p>
      <p className="text-neutral-600 text-base text-center">
        <FormattedMessage {...message.content} />
      </p>
    </div>
  );
}

export default InterviewEnded;
