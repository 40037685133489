export const classnamesConfigMap = {
  chat_link: {
    dialog: 'z-50',
    dialogTitleParent: 'dark:bg-neutral-800 w-auto border-slate-200 dark:border-neutral-700',
    dialogTitleParentBg: 'dark:bg-neutral-800',
    dialogTitle: 'pb-3 border-b border-gray-300 dark:border-neutral-700',
    xMarkPosition: 'top-[7.5%]',
    checkBoxAndButtonParent: 'flex-col gap-3 dark:bg-neutral-800',
    buttonParent: 'w-full justify-end items-center gap-3',
    dialogTitleH3: 'text-lg font-semibold',
  },
  interview_rooms: {
    dialog: 'z-50',
    dialogTitleParent: 'w-[30rem] p-6 border-none dark:bg-neutral-900',
    dialogTitleParentBg: 'dark:bg-neutral-900',
    dialogTitle: 'flex flex-col gap-[0.875rem]',
    xMarkPosition: 'top-[10.5%]',
    checkBoxAndButtonParent: 'dark:bg-neutral-900',
    dialogTitleH3: 'text-xl font-semibold',
    dialogTitleH3Content: 'text-gray-500 dark:text-gray-300 font-normal text-base not-italic',
  },
  invite_people: {
    dialog: 'z-50',
    dialogTitleParent: `h-auto dark:bg-neutral-900 border-none`,
    dialogTitleParentBg: 'dark:bg-neutral-900',
    dialogTitle: 'flex flex-col justify-start items-center gap-1',
    dialogTitleH3: 'text-xl font-medium',
    dialogTitleH3Content: 'dark:text-neutral-400 text-slate-700 text-base font-normal',
    dialogTitleParentChild: 'flex flex-col gap-6',
    childrenClass: 'relative h-full',
    dialogOverlay: 'bg-black bg-opacity-50 transition-opacity',
  },
  code_jam_questions: {
    dialog: 'z-40',
    dialogTitleParent:
      'dark:bg-gray-900 inline-block align-middle overflow-hidden border-gray-200 dark:border-gray-700 !w-4/5 h-4/6 !p-8',
    dialogTitleParentBg: 'dark:bg-gray-900',
    dialogTitle: 'flex flex-col',
    xMarkPosition: 'top-9',
    checkBoxAndButtonParent: 'dark:bg-gray-900',
    dialogTitleH3: 'text-2xl font-bold',
    dialogTitleH3Content: 'text-gray-500 dark:text-gray-300 font-normal text-base not-italic',
    childrenClass: 'flex flex-col gap-6 mt-6',
  },
  confirm_call: {
    dialog: 'z-50',
    dialogTitleParent:
      'dark:bg-neutral-900 inline-block align-middle overflow-hidden border-slate-200 dark:border-neutral-700',
    dialogTitleParentBg: 'dark:bg-neutral-900',
    dialogTitle: 'flex flex-col gap-[0.875rem]',
    xMarkPosition: 'top-[10.5%]',
    checkBoxAndButtonParent: 'dark:bg-neutral-900',
    dialogTitleH3: 'text-xl font-semibold',
    dialogTitleH3Content: 'dark:text-white text-gray-900',
    dialogTitleParentChild: 'flex flex-col gap-6',
    buttonParent: 'gap-3',
  },
  sec_cam_360_env_check: {
    dialog: 'z-50',
    dialogTitleParent:
      'dark:bg-neutral-800 inline-block align-middle overflow-hidden border-slate-200 dark:border-neutral-700',
    dialogTitleParentBg: 'dark:bg-neutral-800',
    dialogTitle: 'flex flex-col gap-[0.875rem]',
    xMarkPosition: 'top-[4%]',
    checkBoxAndButtonParent: 'dark:bg-neutral-800',
    dialogTitleH3: 'text-xl font-medium not-italic',
    dialogTitleH3Content: 'text-gray-500 dark:text-gray-300 font-normal text-base not-italic',
    buttonParent: 'gap-3',
    childrenClass: 'md-h:h-80 md-h:overflow-y-scroll no-scrollbar',
  },
  room_disconnect: {
    dialog: 'z-50',
    dialogTitleParent:
      'dark:bg-neutral-900 inline-block align-middle overflow-hidden border-slate-200 dark:border-neutral-700',
    dialogTitleParentBg: 'dark:bg-neutral-900',
    dialogTitle: 'flex flex-col gap-[0.875rem]',
    xMarkPosition: 'top-[10.5%]',
    checkBoxAndButtonParent: 'dark:bg-neutral-900 justify-center items-center',
    dialogTitleH3Parent: 'justify-center',
    dialogTitleH3: 'text-xl font-medium',
    dialogTitleH3Content: 'text-gray-500 dark:text-white font-normal text-base text-center',
  },
  default: {
    dialog: 'z-50',
    dialogTitleParent:
      'dark:bg-neutral-900 inline-block align-middle overflow-hidden border-slate-200 dark:border-neutral-700',
    dialogTitleParentBg: 'dark:bg-neutral-900',
    dialogTitle: 'flex flex-col gap-[0.875rem]',
    xMarkPosition: 'top-[10.5%]',
    checkBoxAndButtonParent: 'dark:bg-neutral-900',
    dialogTitleH3: 'text-xl font-medium not-italic',
    dialogTitleH3Content: 'text-gray-500 dark:text-gray-300 font-normal text-base not-italic',
  },
};
