import { getAuth, signOut } from 'firebase/auth';
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
import { collection, doc, onSnapshot, query, orderBy, where, getDocs, limit } from 'firebase/firestore';
import EnvConfig from 'utils/envConfig';
import { map, uniq, get, reduce, filter } from 'lodash';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { getItem } from 'utils/store';
import { processToken, processRecruitToken } from 'utils/helpers';
import { candidateRoleCheck } from 'containers/InterviewRooms/utils';
import { FIREBASE_COLLECTION_NAME, FIREBASE_RECEIVER_TYPE } from 'utils/constants';

let app;
let unsubscribe;
let unsubscribePrivateChat;
let unsubscribeSignal;
let unsubscribeParticipant;
let unsubscribeVoipStatus;
let unsubscribeSecCamEnvCheck;
const env_config = EnvConfig.fetchEnvObj();

const getFirebaseEnv = () => {
  const config = {
    apiKey: env_config.FIREBASE_API_KEY || 'mock_key',
    authDomain: env_config.FIREBASE_API_DOMAIN,
    projectId: env_config.FIREBASE_PROJECT_ID,
  };
  return config;
};

if (!getApps().length) {
  const config = getFirebaseEnv();
  app = initializeApp(config);
}

const auth = getAuth(app);
// const firebaseStorage = getStorage(app);
const firebaseDB = getFirestore(app);
const HASURA_HTTP = `${env_config.HASURA_HTTP}`;
const GRAPH_API_HTTP = `${env_config.GRAPH_API_HTTP}`;

const getGroupedAlerts = signalData => {
  try {
    return Object.values(
      reduce(
        filter(signalData, item => item?.message_type && item?.id),
        (acc, curr) => {
          const { message_type, id } = curr;
          if (!acc[message_type]) acc[message_type] = { ...curr, groupedIds: [] };
          acc[message_type].groupedIds?.push(id);
          return acc;
        },
        {},
      ),
    );
  } catch {
    return signalData;
  }
};

const setFirebaseDBref = ({
  document_name,
  isCandidate,
  participantRole,
  setChatMessageData,
  setPrivateChatMessageData,
  setSignalData,
  setParticipantData,
  setVoipStatusData,
  setSecCamEnvCheckData,
  username,
  participantId,
}) => {
  const messageRef = collection(firebaseDB, 'IR_CHAT');
  const messageRefDocument = doc(messageRef, document_name);
  const messageRefDataValue = collection(messageRefDocument, 'ir_public_chat');
  const privateMessageRefDataValue = collection(messageRefDocument, 'ir_private_chat');
  const signalRefDataValue = collection(messageRefDocument, 'ir_singals');
  const participantRefDataValue = collection(messageRefDocument, 'ir_participants');
  const voipStatusRefDataValue = collection(messageRefDocument, 'voip_status');
  const secCamEnvCheckRefDataValue = collection(messageRefDocument, 'sec_env_check');

  if (setChatMessageData) {
    const queryMessage = query(messageRefDataValue, orderBy('created_at', 'asc'));
    unsubscribe = onSnapshot(queryMessage, snapshot => {
      const messagesData = [];
      snapshot.forEach(document => {
        messagesData.push({ ...document.data(), id: document.id });
      });
      setChatMessageData(messagesData);
    });
  }

  if (setPrivateChatMessageData) {
    const queryPrivateMessage = query(privateMessageRefDataValue, orderBy('created_at', 'asc'));
    unsubscribePrivateChat = onSnapshot(queryPrivateMessage, snapshot => {
      const messagesData = [];
      snapshot.forEach(document => {
        messagesData.push({ ...document.data(), id: document.id });
      });
      setPrivateChatMessageData(messagesData);
    });
  }

  if (setSignalData) {
    let reciever = 'Panel';
    if (!participantRole) {
      reciever = isCandidate ? 'Candidate' : 'Panel';
    } else if (candidateRoleCheck(participantRole)) {
      reciever = 'Candidate';
    }
    const querySignal = query(
      signalRefDataValue,
      where('reciever', '==', reciever),
      where('sender', '!=', username),
      orderBy('sender', 'asc'),
      orderBy('created_at', 'asc'),
    );
    unsubscribeSignal = onSnapshot(querySignal, snapshot => {
      const signalData = [];
      snapshot.forEach(document => {
        const data = document.data();
        if (!data?.acknowledged?.includes(Number(participantId))) signalData.push({ ...data, id: document.id });
      });
      const groupedSignalDataList = getGroupedAlerts(signalData);
      setSignalData(groupedSignalDataList);
    });
  }

  const queryParticipant = query(participantRefDataValue, orderBy('created_at', 'desc'));
  unsubscribeParticipant = onSnapshot(queryParticipant, snapshot => {
    const participantDataList = [];
    snapshot.forEach(document => {
      participantDataList.push({ ...document.data(), id: document.id });
    });
    const uniqueParticipantsList = uniq(
      map(participantDataList || [], i => Number(i?.meeting_participant_id || i?.id)),
    );
    setParticipantData(participantDataList, uniqueParticipantsList);
  });

  if (setVoipStatusData) {
    const queryVoipStatus = query(voipStatusRefDataValue, orderBy('created_at', 'desc'));
    unsubscribeVoipStatus = onSnapshot(queryVoipStatus, snapshot => {
      const voipStatusData = [];
      snapshot.forEach(document => {
        voipStatusData.push({ ...document.data(), id: document.id });
      });
      setVoipStatusData(voipStatusData);
    });
  }

  if (setSecCamEnvCheckData) {
    let receiver = FIREBASE_RECEIVER_TYPE.PANEL;
    if (!participantRole) {
      receiver = isCandidate ? FIREBASE_RECEIVER_TYPE.CANDIDATE : FIREBASE_RECEIVER_TYPE.PANEL;
    } else if (candidateRoleCheck(participantRole)) {
      receiver = FIREBASE_RECEIVER_TYPE.CANDIDATE;
    }
    const querySecCamEnvCheck = query(
      secCamEnvCheckRefDataValue,
      where('reciever', '==', receiver),
      orderBy('created_at', 'desc'),
      limit(1),
    ); // using limit , Fetch only the latest document
    unsubscribeSecCamEnvCheck = onSnapshot(querySecCamEnvCheck, snapshot => {
      if (!snapshot?.empty) {
        const latestDoc = snapshot.docs?.[0]; // snapshot.docs is an array , so Get the first document (latest)
        const latestData = { ...latestDoc.data(), id: latestDoc.id };
        setSecCamEnvCheckData(latestData); // Update state with the latest document object
      } else {
        setSecCamEnvCheckData(null);
      }
    });
  }

  return {
    refData: {
      messageRefDataValue,
      privateMessageRefDataValue,
      signalRefDataValue,
      participantRefDataValue,
      voipStatusRefDataValue,
      secCamEnvCheckRefDataValue,
    },
  };
};

const unsubscribeFirebaseDB = () => {
  if (unsubscribe) unsubscribe();
  if (unsubscribePrivateChat) unsubscribePrivateChat();
  if (unsubscribeSignal) unsubscribeSignal();
  if (unsubscribeParticipant) unsubscribeParticipant();
  if (unsubscribeVoipStatus) unsubscribeVoipStatus();
  if (unsubscribeSecCamEnvCheck) unsubscribeSecCamEnvCheck();
};

const reAuthenticateUser = async api_url => {
  try {
    if (api_url === HASURA_HTTP) {
      const refresh_token = get(auth, 'currentUser.refreshToken');
      if (!refresh_token) return 'not defined';
      const api_key = env_config.FIREBASE_API_KEY;
      const end_point = `https://securetoken.googleapis.com/v1/token?key=${api_key}`;
      const request_obj = {
        grant_type: 'refresh_token',
        refresh_token,
      };
      const headers = {
        'Content-Type': 'application/json',
      };
      const { data } = await axios.post(end_point, request_obj, { headers });
      const id_token = get(data, 'id_token') || null;
      if (id_token) {
        processToken(id_token);
      }
      return { id_token };
    }
    if (api_url === GRAPH_API_HTTP) {
      const token = getItem('la_token') || null;
      if (!token) return null;
      const { data } = await axios.post('/api/token', { token });
      const access_token = get(data, 'access_token') || null;
      if (access_token) {
        processRecruitToken(access_token);
      }
      return { access_token };
    }
    Sentry.captureException(new Error('Refresh token not found'));
    return null;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};

async function fetchFbCollectionData(meetingRefDocument, collectionName) {
  const chatRef = collection(meetingRefDocument, collectionName);
  const queryMessage = query(chatRef, orderBy('created_at', 'asc'));

  try {
    // Fetch the documents from the collection
    const snapshot = await getDocs(queryMessage);

    // Process the documents and store them in a variable
    return snapshot.docs.map(document => ({
      ...document.data(),
      id: document.id,
    }));
  } catch (error) {
    console.error('Error fetching firebase collection data:', error);
    return [];
  }
}

const fetchAndStoreFirebaseData = async document_name => {
  try {
    const meetingRef = collection(firebaseDB, FIREBASE_COLLECTION_NAME.IR_CHAT);
    const meetingRefDocument = doc(meetingRef, document_name);
    // Fetch public chat, private chat and codejam data in parallel
    const [codeJamData, publicChatData, privateChatData] = await Promise.all([
      fetchFbCollectionData(meetingRefDocument, FIREBASE_COLLECTION_NAME.CODE_JAM),
      fetchFbCollectionData(meetingRefDocument, FIREBASE_COLLECTION_NAME.PUBLIC_CHAT),
      fetchFbCollectionData(meetingRefDocument, FIREBASE_COLLECTION_NAME.PRIVATE_CHAT),
    ]);
    return { codeJamData, publicChatData, privateChatData };
  } catch (error) {
    console.error('Error fetching data:', error);
    return { codeJamData: [], publicChatData: [], privateChatData: [] }; // Return [] if there is an error
  }
};

export {
  signOut,
  auth,
  firebaseDB,
  setFirebaseDBref,
  unsubscribeFirebaseDB,
  reAuthenticateUser,
  getGroupedAlerts,
  fetchFbCollectionData,
  fetchAndStoreFirebaseData,
};
